import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { DiscussionEmbed } from 'disqus-react';
import Layout from '../Layout';
import PostHeader from '../PostHeader';

import './styles.scss';

const Post = ({ data, pageContext }) => {
  const post = data.markdownRemark;

  const disqus = {
    shortname: 'lovatt-dev',
    config: {
      identifier: post.id,
      title: post.frontmatter.title,
    },
  };

  return (
    <Layout>
      <div className="Post">
        <PostHeader
          frontmatter={post.frontmatter}
          forPost
          seriesLinks={
            (post.frontmatter.series)
              ? (
                { prev: pageContext.prev, next: pageContext.next }
              )
              : (
                null
              )
          }
        />
        <hr />
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <DiscussionEmbed {...disqus} />
      </div>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY|DD/MM/YY")
        description
        series
        part
      }
    }
  }
`;

export default Post;
