import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './styles.scss';

const PostHeader = ({ frontmatter, forPost, seriesLinks }) => {
  const [fullDate, shortDate] = frontmatter.date.split('|');
  const { prev, next } = seriesLinks || {};

  return (
    <section className="PostHeader">
      <h5 className="date full">{fullDate}</h5>
      <h5 className="date short">{shortDate}</h5>
      <h1 className="title">{frontmatter.title}</h1>
      <p className="description small">{frontmatter.description}</p>
      {(frontmatter.series && frontmatter.series !== '')
        ? (
          <React.Fragment>
            {/* <h5 className="series">{frontmatter.series}</h5> */}
            <div className="seriesLinks">
              <Link to={(prev) ? prev.fields.slug : ''} className={(prev) ? '' : 'hide'}>prev</Link>
              <span className="series"> {frontmatter.series}, part {frontmatter.part} </span>
              <Link to={(next) ? next.fields.slug : ''} className={(next) ? '' : 'hide'}>next</Link>
            </div>
          </React.Fragment>
        )
        : null
      }
    </section>
  );
};
PostHeader.propTypes = {
  frontmatter: PropTypes.shape({}).isRequired,
  forPost: PropTypes.bool,
};

PostHeader.defaultProps = {
  forPost: false,
};

export default PostHeader;
